/* eslint-disable react/prop-types */
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { FieldInputProps, FieldMetaProps, useField } from 'formik';
import { FlattenInterpolation, ThemeProps } from 'styled-components';
import { SearchIcon } from 'components/Layout/Icons';

import * as S from './styled';

const DEFAULT_PROPS = {
  withFloatingLabel: false,
  type: 'text',
  label: '',
  inputStyles: [],
  labelStyles: [],
  inputWrapperStyles: [],
  disabled: false,
  meta: {},
};

type DefaultInputPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type FieldType<Value> = Partial<FieldInputProps<Value>> & {
  meta?: FieldMetaProps<Value>;
};

type PropsType = {
  inputStyles?: FlattenInterpolation<ThemeProps<any>>[];
  labelStyles?: FlattenInterpolation<ThemeProps<any>>[];
  inputWrapperStyles?: FlattenInterpolation<ThemeProps<any>>[];
  label?: string;
  name: string;
  withFloatingLabel?: boolean;
  disabled?: boolean;
};

type TextInputWithStateType = DefaultInputPropsType & PropsType;
type TextInputType<Value> = PropsType & DefaultInputPropsType & FieldType<Value>;

export const TextInput = <Value,>(props: TextInputType<Value>) => {
  const {
    inputStyles,
    labelStyles,
    label,
    placeholder,
    withFloatingLabel,
    onChange,
    type,
    meta,
    value,
    name,
    disabled,
    inputWrapperStyles,
  } = props;

  return (
    <S.Wrapper type={type} styles={inputWrapperStyles}>
      <S.TextInput
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder || ' '}
        styles={inputStyles}
        disabled={disabled}
        autoComplete="off"
      />
      {withFloatingLabel && <S.Label styles={labelStyles}>{label}</S.Label>}
      {meta?.touched || meta?.error ? <S.ErrorMessage>{meta.error}</S.ErrorMessage> : null}
      {type === 'search' && <SearchIcon />}
    </S.Wrapper>
  );
};

export const TextInputWithState = ({ name, ...restProps }: TextInputWithStateType) => {
  const [field, meta] = useField(name);

  return <TextInput {...field} {...restProps} name={name} meta={meta} />;
};

TextInput.defaultProps = DEFAULT_PROPS;
TextInputWithState.defaultProps = DEFAULT_PROPS;

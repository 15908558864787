type MappedStringTypes = Record<
  | 'UnitId'
  | 'ItemId'
  | 'UnitName'
  | 'ItemName'
  | 'SurveyProjectId'
  | 'SurveyProjectsItemId'
  | 'SurveyProjectName'
  | 'SurveyProjectsItemName'
  | 'LinkFile'
  | 'MimeType'
  | 'Name'
  | 'Id'
  | 'CreatedOn',
  string
>;

export type ItemPdfType = MappedStringTypes & {
  SizeInBytes: number;
};

interface ManagerType {
  Email: string;
  SubmittedPassword: null;
  Phone: string;
  UserType: string;
  Status: string;
  JobFunctionID: string;
  JobFunctionName: string;
  LocationsCount: number;
  TasksCount: number;
  OverdueTasksCount: number;
  IsHidden: boolean;
  SendDailyMail: boolean;
  SendIotMail: boolean;
  SendIotTxt: boolean;
  SendIotPush: boolean;
  FirstName: string;
  LastName: string;
  UserAvailabilityOptionID: string;
  UserAvailabilityOptionName: string;
  Name: string;
  Id: string;
  CreatedOn: string;
}

export interface RuleDataType {
  MaxQty: number | null;
  MinQty: number | null;
  ActualQty: number | null;
  MaxFlow: number | null;
  MinFlow: number | null;
  ActualFlow: number | null;
  MaxTemperature: number | null;
  MinTemperature: number | null;
  ActualTemperature: number | null;
  MaxPhotos: number | null;
  MinPhotos: number | null;
  CurrentPhotos: number | null;
  ExpectedScanCode: string | null;
  ActualScannedCodeId: string | null;
  ActualScannedCodeValue: string | null;
  FeedbackQuestion: string | null;
  FeedbackResponse: string | null;
  VerificationSpecification: string | null;
  VerificationResponse: boolean | null;
  SignatureSpecification: string | null;
  SignatureResponse: string | null;
  UnitOfMeasure: string | null;
}

export interface PartType {
  UpdatedOn: string;
  MyPartID: string;
  MyPartName: string;
  MyPartCategoryID: string;
  MyPartCategory: null;
  MyPartTypeID: string;
  MyPartType: null;
  MyPartUnitOfMeasureID: string;
  MyPartUnitOfMeasure: null;
  MyPartUOMCountDetail: null;
  MyPartDescription: null;
  MyPartSerialNumber: null;
  MyPartRevisionNumber: number;
  MyPartRevisionDate: null;
  MyPartURL: null;
  MyPartCreationDateTime: null;
  MyPartRevisionDateTime: null;
  MyPartSizingStandardID: null;
  MyPartSizingStandard: null;
  MyPartWidth: null;
  MyPartHeight: null;
  MyPartDepth: null;
  MyPartGirth: null;
  MyPartMaterialTypeID: null;
  MyPartMaterialType: null;
  MyPartWeightStandardID: null;
  MyPartWeightStandard: null;
  MyPartWeight: null;
  MyPartColor: null;
  MyPartValue: number;
  Name: string;
  Id: string;
  CreatedOn: string;
}

export interface PartGroupType {
  Description: string;
  LinksCount: number;
  Name: string;
  Id: string;
  CreatedOn: string;
}

export type StatusSensorType = 'Normal' | 'Elevated' | 'Watch' | 'Warning' | 'Alert' | 'Disconnect';

export interface TaskType {
  AssignedChecklistId: string;
  IsActive: boolean;
  Manager: ManagerType;
  ManagedBy: string;
  OriginatingChecklistId: string;
  OriginatingChecklistName: string;
  ExecutionDate: string;
  AssigneeId: string;
  AssigneeName: string;
  OriginatingTaskId: string;
  OriginatingTaskName: string;
  ItemId: string;
  ItemName: string;
  ItemNotes: string;
  ItemInstructions: string;
  ItemSequence: number;
  UnitId: string;
  UnitName: string;
  Status: string;
  RuleType: string;
  CompletedBy: string;
  CompletedOn: string;
  IsLocked: boolean;
  LockedBy: string;
  TeamId: string;
  TeamName: string;
  CompletedOnWithTime: string;
  ArchivedBy: string;
  ArchivedOn: string;
  AttentionRequiredBy: string;
  ArchivedOnWithTime: string;
  HasBeenSignedOff: boolean;
  IsOpenStatus: boolean;
  IsDemoStatus: boolean;
  StatusSensor: StatusSensorType;
  SignoffBy: string;
  SignoffOn: string;
  SignoffComment: string;
  InstructionalNotes: string;
  Exceptions: string;
  UserComments: string;
  SiteId: string;
  SiteName: string;
  AdditionalSiteID: string;
  TaskOrder: number;
  RuleData: RuleDataType;
  IsStrictOrderItem: boolean;
  TaskPartsRequireAttention: boolean;
  AssignedTaskParts: Array<unknown>;
  Parts: Array<PartType>;
  PartGroups: Array<PartGroupType>;
  PartResolution: string;
  ImageQty: number;
  TaskType: string;
  TaskResult: string;
  Id: string;
  CreatedOn: string;
}

export type ReportPhotoType = {
  AssignedTaskId: string;
  CreatedOn: string;
  FileId: string;
  FileName: string;
  Id: string;
  IsIncludedIntoReport: boolean;
};

export type PartIssueEstimationType = {
  assignedTaskId: string;
  estimatedHours?: number;
  estimatedMaterials?: number;
  approvedBy?: string;
  approvedByRole?: string;
  approvalNotes?: string;
  approvedOn?: Date;
};

// eslint-disable-next-line no-shadow
export enum TASK_RULE_TYPES {
  VERIFICATION = 'Verification',
  FEEDBACK = 'Feedback',
  SCAN_CODE = 'Scan Code',
  PHOTO_CONFIRMATION = 'Photo Confirmation',
  CHECK_QUANTITY = 'Check Quantity',
  CHECK_FLOW_RATE = 'Check Flow Rate',
  CHECK_TEMPERATURE = 'Check Temperature',
  SIGNATURE = 'Signature',
  REPLACE_PARTS = 'PartReplacement',
  REPORT_COMPLETION = 'ReportCompletion',
  REPORT_PROGRESS = 'ReportProgress',
}
